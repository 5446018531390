import { Box, Grid, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import {
  AuditInfo,
  KeywordsInfo,
  PlaceInfo,
  ChartPoint,
} from '../../api/api_types';
import { useParams } from 'react-router-dom';
import { getAuditInfo } from '../../api/api';
import './style.css';
import InfoLabel from './info_label';
import Title from './title';
import Services from './service';
import Map from './map';
import { ScatterChart } from '@mui/x-charts';
import LocationAnalysis from './location_analysis';
import * as en from '../lang/en.json';
import * as pl from '../lang/pl.json';
import * as ua from '../lang/ua.json';
import * as de from '../lang/de.json';


function getColor(value?: number) {
  if (!value) {
    return '#C54A49';
  } else if (value < 4) {
    return '#38B137';
  } else if (value < 7) {
    return '#8AB137';
  } else if (value < 10) {
    return '#B1AC37';
  } else if (value < 13) {
    return '#B18037';
  } else if (value < 16) {
    return '#B16337';
  } else if (value < 19) {
    return '#B14D37';
  } else if (value < 20) {
    return '#B62727';
  } else {
    return '#C54A49';
  }
}

function getBackgroundColor(value: number) {
  const color = getColor(value);
  const rgbColor = hexToRgb(color);
  const rgbaColor = `rgba(${rgbColor.r}, ${rgbColor.g}, ${rgbColor.b}, 0.15)`;
  return rgbaColor;
}

function hexToRgb(hex: string) {
  const bigint = parseInt(hex.substring(1), 16);
  const r = (bigint >> 16) & 255;
  const g = (bigint >> 8) & 255;
  const b = bigint & 255;
  return { r, g, b };
}

export default function AuditPage() {
  const { id } = useParams();
  const [auditInfo, setInfo] = useState<AuditInfo>();
  const [mapVisibility, setVisibility] = useState(false);
  const [lang, setLang] = useState(en);


  const mainInfo = [
    lang.auditMainInfo1,
    lang.auditMainInfo2,
    lang.auditMainInfo3,
  ];

  const secondaryInfo = [
    lang.auditSecondaryInfo1,
    lang.auditSecondaryInfo2,
    lang.auditSecondaryInfo3,
  ];

  const date = new Date(auditInfo?.createdAt ?? '');

  const formattedDate = `${date.getDate()}.${date.getMonth() + 1
    }.${date.getFullYear()}`;

  useEffect(() => {
    async function init() {
      const info = await getAuditInfo(id ?? '');

      setInfo(info);

      switch (info?.language) {
        case 'En':
          setLang(en);
          break;
        case 'Pl':
          setLang(pl);
          break;
        case 'De':
          setLang(de);
          break;
        default:
          setLang(ua);
          break;
      }

      setVisibility(true);
    }

    init();
  }, []);

  function getChartPoint(info?: ChartPoint) {
    return [
      {
        x: info?.reviews ?? 0,
        y: info?.reviewRank ?? 0,
        id: `${info?.reviews} ${info?.reviewRank}`,
      },
    ];
  }

  return (
    <div
      style={{
        maxWidth: '794px',
        margin: '0 auto',
        height: '100vh',
      }}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          paddingTop: '50px',
          marginBottom: '22px',
        }}
      >
        <img src='/images/audit/logo.svg' />
        <h5
          style={{ fontWeight: 300, fontSize: '14px' }}
        >{`${lang.auditDate} ${formattedDate}`}</h5>
      </div>

      <h1
        style={{
          fontWeight: 700,
          fontSize: '24px',
          lineHeight: '24px',
          marginBottom: '6px',
        }}
      >
        {`${lang.auditSeo} “${auditInfo?.name}”`}
      </h1>
      <h5 style={{ fontWeight: 300, fontSize: '12px', marginBottom: '30px' }}>
        {auditInfo?.address}
      </h5>

      {auditInfo?.descriptionHtml && <>
        <h2
          style={{
            fontSize: '20px',
            fontWeight: 500,
            color: '#3780FF',
            marginBottom: '10px',
          }}
        >
          <Title text={lang.auditGoogle} />
        </h2>
        <div style={{
          lineHeight: '1.5'
        }} dangerouslySetInnerHTML={{ __html: auditInfo?.descriptionHtml }}></div>
      </>}

      <h2
        style={{
          fontSize: '20px',
          fontWeight: 500,
          color: '#3780FF',
          marginBottom: '10px',
        }}
      >
        <Title text={lang.auditAnalysis} />
      </h2>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Analytics keywords={auditInfo?.keywords} lang={lang} />
        </Grid>
        <Grid item xs={6} container spacing={1}>
          <Grid item xs={6}>
            <StatisticItem
              icon='/images/audit/audit_icon_1.svg'
              title={auditInfo?.searchPosition ?? '21+'}
              subtitle={lang.average}
            />
          </Grid>
          <Grid item xs={6}>
            <StatisticItem
              icon='/images/audit/audit_icon_2.svg'
              title={`${auditInfo?.shareTopPositionRequests}%`}
              subtitle={lang.topRequest}
            />
          </Grid>
          <Grid item xs={6}>
            <StatisticItem
              icon='/images/audit/audit_icon_3.svg'
              title={auditInfo?.competitors}
              subtitle={lang.competitors}
            />
          </Grid>
          <Grid item xs={6}>
            <StatisticItem
              icon='/images/audit/audit_icon_4.svg'
              title={auditInfo?.winnedCompetitors}
              subtitle={lang.lowerRatedCompetitors}
            />
          </Grid>
        </Grid>
        <Grid item xs={6}>
          <Locations
            currentPlace={auditInfo?.currentPlace}
            competitorPlaces={auditInfo?.competitorPlaces}
            lang={lang}
          />
        </Grid>
        <Grid item xs={6}>
          <Map
            visibility={mapVisibility}
            isReviewChar={false}
            points={auditInfo?.points ?? []}
            defaultZoom={auditInfo?.defaultMapZoom ?? 12.8}
            initialCenter={auditInfo?.initialCenter}
          />
        </Grid>
      </Grid>
      <InfoLabel info={mainInfo} />
      <div className='no-wrap_container'>
        <Title text={lang.feedbackAnalysis} />
        <Box display='flex'>
          <Box
            width={389}
            height={400}
            sx={{
              backgroundColor: '#fff',
              borderRadius: '6px',
              padding: '14px 12px',
              border: '1px solid #ccc',
              mr: '16px',
            }}
          >
            <ScatterChart
              margin={{ top: 30, bottom: 100 }}
              xAxis={[{ label: lang.reviews }]}
              yAxis={[{ label: lang.rating }]}
              series={[
                {
                  label: auditInfo?.name,
                  color: '#3780FF',
                  data: getChartPoint(
                    auditInfo?.reviewChartPoints.find((e) => e.currentPlace),
                  ),
                },
                {
                  label: lang.competitors,
                  color: '#000',
                  data:
                    auditInfo?.reviewChartPoints
                      .filter((e) => !e.currentPlace)
                      .map((e) => ({
                        x: e.reviews,
                        y: e.reviewRank,
                        id: `${e.reviews} ${e.reviewRank}`,
                      })) ?? [],
                },
              ]}
            />
            <Box className='chart_title' display='flex' alignItems='center'>
              <Box
                width={15}
                height={15}
                mr='6px'
                sx={{ backgroundColor: 'rgb(55, 128, 255)' }}
              />
              <Typography
                mr='20px'
                fontSize='14px'
                maxWidth={150}
                whiteSpace='nowrap'
                overflow='hidden'
                textOverflow='ellipsis'
              >
                {auditInfo?.name}
              </Typography>
              <Box
                width={15}
                height={15}
                mr='6px'
                sx={{ backgroundColor: '#000' }}
              />
              <Typography
                fontSize='14px'
                maxWidth={150}
                whiteSpace='nowrap'
                overflow='hidden'
                textOverflow='ellipsis'
              >
                {lang.competitors}
              </Typography>
            </Box>
          </Box>
          <Box width={389}>
            <Map
              visibility={mapVisibility}
              points={auditInfo?.reviewPoints ?? []}
              isReviewChar={true}
              defaultZoom={auditInfo?.defaultMapZoom ?? 12.8}
              initialCenter={auditInfo?.initialCenter}
            />
          </Box>
        </Box>
      </div>

      <InfoLabel info={secondaryInfo} />
      <LocationAnalysis info={auditInfo?.pageOptimizationItems ?? []} />
      {auditInfo?.services && auditInfo?.services.length > 0 && <Services services={auditInfo?.services} />}
      <Box height={25}></Box>
    </div>
  );
}

interface AnalyticsProps {
  keywords?: KeywordsInfo[];
  lang: typeof en | typeof ua | typeof de | typeof pl;
}

function Analytics(props: AnalyticsProps) {
  const { keywords, lang } = props;

  function getFormatedDistance(distance: number) {
    if (distance < 1000) {
      return `${distance}${lang.distanceM}`;
    } else {
      return `${distance / 1000}${lang.distanceKm}`;
    }
  }

  return (
    <div
      style={{
        backgroundColor: '#fff',
        borderRadius: '6px',
        padding: '14px 12px',
        height: '124px',
        border: '1px solid #ccc',
      }}
    >
      {keywords?.map((item, itemIndex) => {
        return (
          <div key={itemIndex}>
            <Box
              display='flex'
              justifyContent='space-between'
              alignItems='center'
            >
              <Box>
                <h5 style={{ fontSize: '12px', fontWeight: 400 }}>
                  {item.keyword}
                </h5>
                <h6
                  style={{
                    fontSize: '10px',
                    fontWeight: 400,
                    color: '#868686',
                  }}
                >
                  {`${item.requests} ${lang.requests}`}
                </h6>
              </Box>
              <Box display='flex'>
                {item.levels.map((el, index) => {
                  return (
                    <Box
                      key={index}
                      sx={{
                        height: 28,
                        width: 28,
                        backgroundColor: el.hide
                          ? '#3780FF'
                          : getColor(el.position),
                        borderRadius: 50,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        color: '#fff',
                        fontSize: '12px',
                        fontWeight: 400,
                        border: '1px solid #FFFFFFB2',
                        zIndex: item.levels.length - index,
                        ml: '3px',
                        position: 'relative',
                        '&::after':
                          itemIndex === 0
                            ? {
                              top: -16,
                              position: 'absolute',
                              content: `"${getFormatedDistance(
                                el.distance,
                              )}"`,
                              fontSize: '10px',
                              fontWeight: 400,
                              color: '#868686',
                            }
                            : {},
                      }}
                    >
                      {el.hide ? '?' : el.position ?? '21+'}
                    </Box>
                  );
                })}
              </Box>
            </Box>
          </div>
        );
      })}
    </div>
  );
}

interface ItemProps {
  icon: string;
  title?: string | number;
  subtitle: string;
}

function StatisticItem(props: ItemProps) {
  const { icon, title, subtitle } = props;
  return (
    <div
      style={{
        backgroundColor: '#fff',
        height: '58px',
        display: 'flex',
        alignItems: 'center',
        borderRadius: '6px',
        padding: '14px 12px',
        border: '1px solid #ccc',
      }}
    >
      <img src={icon} />
      <Box ml={'10px'}>
        <Typography fontSize={'20px'} fontWeight={500} lineHeight={'24px'}>
          {title}
        </Typography>
        <Typography
          fontSize={'10px'}
          fontWeight={4}
          lineHeight={'10px'}
          color={'#868686'}
        >
          {subtitle}
        </Typography>
      </Box>
    </div>
  );
}

interface LocationsProps {
  currentPlace?: PlaceInfo;
  competitorPlaces?: PlaceInfo[];
  lang: typeof en | typeof ua | typeof de | typeof pl;
}

function Locations(props: LocationsProps) {
  const { currentPlace, competitorPlaces, lang } = props;

  return (
    <div
      style={{
        backgroundColor: '#fff',
        borderRadius: '6px',
        padding: '14px 12px',
        overflow: 'hidden',
        border: '1px solid #ccc',
      }}
    >
      <LocationItem item={currentPlace} isCurrentItem={true} lang={lang} />
      <div
        style={{
          backgroundColor: '#D9D9D9',
          width: '100%',
          height: '1px',
          margin: '8px 0',
        }}
      ></div>
      {competitorPlaces?.map((item, index) => {
        return <LocationItem item={item} key={index} lang={lang} />;
      })}
    </div>
  );
}

function LocationItem({
  item,
  isCurrentItem,
  lang,
}: {
  item?: PlaceInfo;
  isCurrentItem?: boolean;
  lang: typeof en | typeof ua | typeof de | typeof pl;
}) {
  function getIcon(index: number) {
    const rate = item?.reveiwRank ?? 0;
    const startNumber = index + 1;

    const remainder = +(rate % 1).toFixed(1);

    if (rate >= startNumber) {
      return '/images/audit/star_filled.svg';
    } else if (remainder >= 0.3 && remainder <= 0.7) {
      return '/images/audit/star_semi.svg';
    } else if (remainder > 0.7 && startNumber === Math.ceil(rate)) {
      return '/images/audit/star_filled.svg';
    } else {
      return '/images/audit/star_empty.svg';
    }
  }

  return (
    <div
      style={{
        backgroundColor: '#fff',
        display: 'flex',
        alignItems: 'center',
        marginBottom: '10px',
      }}
    >
      <Box height={56} width={56} mr='8px'>
        <img
          style={{
            width: 56,
            height: 56,
            borderRadius: '6px',
            objectFit: 'cover',
          }}
          src={`https://maps.googleapis.com/maps/api/place/photo?maxwidth=400&photo_reference=${item?.photoReference}&key=${process.env.REACT_APP_GOOGLE_KEY}`}
        />
      </Box>
      <Box maxWidth='310px'>
        <Box display='flex'>
          <Box
            display='flex'
            alignItems='center'
            sx={{
              backgroundColor: getBackgroundColor(item?.position ?? 0),
              borderRadius: '4px',
            }}
            mr='3px'
            p='2px 4px'
          >
            <h6
              style={{
                fontSize: '10px',
                fontWeight: 400,
                lineHeight: '8px',
                color: getColor(item?.position ?? 0),
                whiteSpace: 'nowrap',
              }}
            >
              {item?.position + lang.place}
            </h6>
          </Box>
          <Typography
            fontSize='12px'
            fontWeight={400}
            color={isCurrentItem ? '#3780FF' : '#000'}
            overflow='hidden'
            textOverflow='ellipsis'
            whiteSpace='nowrap'
          >
            {item?.name}
          </Typography>
        </Box>
        <Typography fontSize='12px' color='#C3C3C3' fontWeight={300}>
          {item?.address}
        </Typography>
        <Box display='flex'>
          {Array.from({ length: 5 }, (_, index) => (
            <img key={index} src={getIcon(index)} />
          ))}
          <span
            style={{ color: '#868686', fontSize: '10px', marginLeft: '4px' }}
          >
            ({item?.reveiwRank})
          </span>
        </Box>
      </Box>
    </div>
  );
}
